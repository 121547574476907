<template>
  <div id="user-data">
    <div class="heading">
      <h5>Complete seu cadastro na <strong>Inside Brasil</strong></h5>
    </div>
    <div class="form">
      <text-field-mask
        id="name"
        v-model="cep"
        :textFieldError="errorCep"
        :value="cep"
        label="CEP"
        mask="##### - ###"
        placeholder="CEP"
        type="tel"
        @search="searchAddress"
        ><p>
          <a
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_blank"
          >
            Não sabe seu CEP? Clique aqui
          </a>
        </p></text-field-mask
      >

      <text-field
        id="city"
        v-model="city"
        :value="city"
        disabled
        label="Cidade"
        placeholder="Cidade"
        type="text"
      />
      <text-field
        id="state"
        v-model="state"
        :value="state"
        disabled
        label="Estado"
        placeholder="Estado"
        type="text"
      />
      <custom-button
        :disabled="!isValidSubmitForm"
        :loading="loading"
        class="mt-10"
        icon
        text="Confirmar"
        @click="signUp()"
      >
        Confirmar
        <div slot="icon">
          <span class="material-icons material-icons-outlined">
            arrow_forward
          </span>
        </div>
      </custom-button>
      <custom-button outlined text="Voltar" @click="$router.back()">
      </custom-button>
      <div v-if="hasSubmitError" class="card-error">
        <span>Erro ao cadastrar verifique seus dados</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import TextField from "@/components/Form/TextField/index.vue";
import TextFieldMask from "@/components/Form/TextFieldMask/index.vue";
import { apiCep, apiIBGE } from "@/api/config";
import auth from "@/api/services/auth.js";
import CustomButton from "@/components/Button";

export default {
  name: "user-data",
  directives: { mask },
  components: {
    CustomButton,
    TextField,
    TextFieldMask,
  },
  data() {
    return {
      cep: null,
      state: null,
      city: null,
      region: null,
      errorCep: null,
      loading: false,
      hasSubmitError: false,
    };
  },
  computed: {
    isValidSubmitForm() {
      let isValid = false;
      if (this.cep && this.city && this.state) {
        isValid = true;
      }
      return isValid;
    },
  },
  methods: {
    async signUp() {
      this.loading = true;
      try {
        const response = await auth.completeUserRegistration({
          name: this.$route.params.data.name,
          cpf: this.$route.params.data.cpf,
          birth_date: new Date(this.$route.params.data.birth_date),
          sex: this.$route.params.data.sex,
          profession: this.$route.params.data.profession,
          phone: this.$route.params.data.phone,
          zipcode: this.cep,
          city: this.city,
          region: this.region,
          state: this.state,
          family_income_id: this.$route.params.data.family_income_id,
          gender_id: this.$route.params.data.gender_id,
          friendly_invitation_code:
            this.$route.params.data.friendly_invitation_code,
          who_indicated: this.$route.params.data.friendly_invitation_code,
          schooling_id: this.$route.params.data.schooling_id,
        });
        localStorage.setItem("user", JSON.stringify(response.data.user));
        this.loading = false;
        this.$router.push({ name: "main" });
      } catch (error) {
        this.loading = false;
        this.hasSubmitError = true;
      }
    },
    async searchAddress() {
      const response = await apiCep({
        url: `/${this.cep.replace(/\D/g, "")}/json`,
        method: "GET",
      });
      if (response.data.erro) {
        this.errorCep = "CEP não existe";
      } else {
        const { localidade, uf } = response.data;
        this.city = localidade;
        this.state = uf;
        this.getRegion(uf);
      }
    },
    async getRegion(uf) {
      const response = await apiIBGE({
        url: `/${uf}/regioes-intermediarias`,
      });
      const regionObject = response.data.find(
        (element) => element.UF.sigla === uf
      );
      this.region = regionObject.UF.regiao.nome;
    },
  },
};
</script>

<style scoped>
#user-data {
  width: 100%;
}
.heading {
  margin-bottom: 10px;
  color: #0d0c22;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 1.2rem;
}
.text-label {
  font-size: 0.875rem;
}
.form {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  justify-content: center;
}
.footer-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.terms {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 5px;
}
.terms .form-control {
  margin-right: 15px;
}
.terms-text {
  font-size: 12px;
}
.text-description {
  margin: 15px 0px;
  font-size: 12px;
  text-align: justify;
  color: #8c8c8c;
}
</style>
